App.initialize = function () {
	// retrieve content container & clear it
	App.DomView = $('.View').html('');

	// initialize overall components
	App.router = new App.Router();
	App.navigation = new App.Components.Navigation();
	App.preloader = new App.Components.Preloader();

	// prepare preloader
	App.preloader.prepare($('.Preloader'));

	// grab icon svg
	App.Util.provideSvgSpritesheet(function () {
		App.Timer.set(1500, function () {
			// prepare navigation view
			App.navigation.prepare();

			// force initial view on backbone history
		 	App.router.forceViewByFragment();

		 	// show navigation
			App.navigation.show();
		});

		App.Timer.set(500, function () {
			App.preloader.$el.addClass('Preloader--alwaysShowNavigation');
		});
	});

};