App.Components.Preloader = (function () {
	var template = TPL['templates/components/preloader.hbs'];

	var Preloader = {

		initialize: function () {

		},

		prepare: function ($el) {
			this.$el = $el || $(template());

			if (!$el) {
				App.DomView.prepend(this.$el);
			}
		},

		show: function () {
			this.$el.removeClass('Preloader--hidden');
		},

		update: function () {

		},

		hide: function () {
			this.$el.addClass('Preloader--hidden');
		},

		destroy: function () {

		}
	};

	return Backbone.View.extend(Preloader);
})();