App.Components.HistoryCard = (function () {

	var HistoryCard = {

		template: TPL['templates/components/historyCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;
			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
		}
	};

	return Backbone.View.extend(HistoryCard);
})();