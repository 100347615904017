App.ClientProvider = (function () {
	return {
		requestAnimationFrame: function () {
			// request animation frame
			window.requestAnimationFrame = (function(){
				return  window.requestAnimationFrame       ||
						window.webkitRequestAnimationFrame ||
						window.mozRequestAnimationFrame    ||
						function( callback ){
							window.setTimeout(callback, 1000 / 60);
						};
			})();
		}
	};
})();