App.ProjectModel = (function () {
	var ProjectModel = {
		initialize: function (project) {
			if (project.subject) { this.set('subject', App.Util.nl2p(project.subject)); }
			if (project.solution) { this.set('solution', App.Util.nl2p(project.solution)); }
			if (project.idea) { this.set('idea', App.Util.nl2p(project.idea)); }

			if (project.memberList !== '') {
				var members = project.memberList.split(',');
				var list = Array();
				for (var i = 0; i < members.length; i++) {
					var member = members[i].split(';');

					list.push({
						name: member[0],
						role: member[1]
					});
				}

				this.set('members', list);

			}
		}
	};

	return Backbone.Model.extend(ProjectModel);
})();