App.Util = (function(){

    /* alle utilities hier in plaatsen */
    /* Util.api = "http://student.howest.be/thomas.cappelle/20122013/RMD2/TRIPIT/api"; */


    return {
    	generateRoutesObject: function () {
    		var pages = App.Config.pages,
    			list = { 
    				'' : 'load',
    				's/:title' : 'load'
    			};

    		for ( var i = 0; i < pages.length; i ++) {
    			var p = pages[i];

    			list[p.name] = 'load';
    		}

    		return list;
    	},

        provideSvgSpritesheet: function (callback) {
            $.ajax({
                url: '/assets/images/spritesheet.svg',
                dataType: 'html',
                success: function (data) {
                    $('body').prepend(data);
                    callback();
                },
                error: function (e) {
                    console.log('Spritesheet could not be loaded!');
                    console.log(e);
                }
            });
        },

        isFragment: function (str, index) {
            index = index || 0;
            return Backbone.history.fragment.split('/')[index] === str;
        },

        getFragment: function (index) {
            index = index || 0;
            return Backbone.history.fragment.split('/')[index];
        },

        getViewportBounds: function () {
            var $win = $(window);

            var top =       $win.scrollTop(),
                left =      $win.scrollLeft(),
                width =     $win.width(),
                height =    $win.height();

            return {
                top:        top,
                left:       left,
                width:      width,
                height:     height,
                right:      left + width,
                bottom:     top + height
            };
        },

        isInViewport: function ($el, viewport) {
            viewport = viewport || App.Utils.getViewportDimentions();

            var offset = $el.offset();

            return  (offset.top + $el.height() > viewport.top && offset.left + $el.height() > viewport.left) && 
                    (offset.top < viewport.bottom && offset.left < viewport.right);
        },


        generateShareLinks: function (detail) {
            var url = 'http://www.nicolasjaenen.be/s/' + detail.title.replace(' ', '_'),
                eUrl = encodeURIComponent(url);

            return {
                facebook: 'https://www.facebook.com/sharer/sharer.php?u=' + eUrl,
                twitter: 'http://twitter.com/share?text=' + detail.intro + '&url=' + eUrl,
                google: 'https://plus.google.com/share?url=' + eUrl + '&hl=en',
                pintrest: 'http://www.pinterest.com/pin/create/button/?url=' + eUrl + '&media=' + encodeURIComponent('http://www.nicolasjaenen.be/uploads/works/' + detail.id + '/' + Math.round( Math.random() * detail.imageCount ) + '.jpg') + '&guid=sh1fCXXQZjbC-0&description=' + encodeURIComponent(detail.intro)
            };
        },

        nl2p: function (str, c){
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1 </p><p' + ((c)? ' class="' + c + '"': '') + '> $2');
        },

        provideCollection: function (key, obj, callback) {
            if (!App.Instances[key]) {
                var collection = new obj(),
                    self = this;
                
                App.Instances[key] = collection;
                
                collection.fetch({
                    remove: true,
                    success: App.Util.provideCollection.bind(this, key, obj, callback)
                });
            }
            else {
                callback(App.Instances[key]);
            }
        },

        polarToCartesian: function (centerX, centerY, radius, angleInDegrees) {
            var angleInRadians = angleInDegrees * Math.PI / 180.0;
            var x = centerX + radius * Math.cos(angleInRadians);
            var y = centerY + radius * Math.sin(angleInRadians);
            return [x,y];
        },

        detectTouch: function () {
            return 'ontouchstart' in window;
        },

        detectHighDensityScreen: function (){
            return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
        },

        detectRetinaScreen: function (){
            return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
        }
    };
})();