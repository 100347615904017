App.Router = (function () {

	var Router = {
		routes: {
			'': 				'main',
			'default': 			'main',
			':mod(/)': 			'main',
			's/:name(/)': 		'show',
			'debug/:page': 		'debug'
		},

		currentPageIsDefault: false,

		initialize: function () {
			var self = this;

			// define on route change for main path
			this.on('route:main', function (page) {
				self.setViewByPageName(page);
			});

			// define on route change for show (detail) path
			this.on('route:show', function (project) {
				self.setViewByName('Show');
				this.currentPageIsDefault = false;
				//self.currentView.setProject(project);
			});

			// define on route change for debug paths
			this.on('route:debug', function (page) {
				self.setViewByName(page);
			});

			// activate backbone history
			Backbone.history.start({ 
				pushState: true, 
				silent:true 
			});

			// override url
			$(document).on("click", "a", function (e) {
				var $this = $(this),
				href = $this.attr("href");

				if (href !== undefined && href === "" || href.indexOf('/') === 0) {
					e.preventDefault();
					self.navigate(href, {trigger: true});
					return;
				}
			});
		},

		setViewByPageName: function (page) {

			// get next view
			var criteria = (!page)? { default: true } : { name: page };
			var p = _.find(App.Config.pages, criteria);		

			if (p.default === false || (p.default && !this.currentPageIsDefault) ) {
				this.currentPageIsDefault = p.default;
				this.setViewByName(p.view);
			}
		},

		setViewByName: function (name) {
			this.nextView = new App.Views[name]();

			// show preloader
			App.preloader.show();

			// show hide and prepare
			if (this.currentView) {
				this.currentView.hide();
			}
			else {
				this.currentView = this.nextView;
				this.nextView = null;
				this.currentView.prepare();
			}
		},

		forceViewByFragment: function (fragment) {
			// force fragment to trigger event by changing its initial value
			fragment = fragment || Backbone.history.fragment;			
			Backbone.history.fragment = 'default';	
			this.navigate(fragment, {trigger: true});
		},

		// catch view events
		onPrepare: function (view) {
			this.currentView.show();
		},

		onShow: function (view) {
			// tracking
			if (App.Config.tracking.active) {
				App.Timer.set(250, this.track.bind(this));
			}
		},

		onUpdate: function (view) {
		},

		onHide: function (view) {

			$('html, body').animate({ scrollTop:0 }, '100', 'swing', this.onScrolledToTopAfterHide.bind(this));
		},

		onDestroy: function (view) {
		},

		onScrolledToTopAfterHide: function () {
			// Seems to be fired 2 times
			if (!this.nextView) {
				return false;
			}

			this.currentView.destroy();
			this.currentView = this.nextView;
			this.nextView = null;
			this.currentView.prepare();
		},

		track: function () {
			var path = Backbone.history.getFragment();
			ga('send', 'pageview', {page: "/" + path});
		}

	};
	return Backbone.Router.extend(Router);
})();