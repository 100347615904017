App.Views.About = (function () {

	var About = {

		template: TPL['templates/about.hbs'],

		initialize: function () {
			var fragment = Backbone.history.fragment.split('/');
			App.navigation.setCurrentItemByName(fragment[0]);
		},

		prepare: function () {
			// get data
			var self = this;

			App.Util.provideCollection('aboutDataCollection', App.AboutDataCollection, function (collection) {
				self.collection = collection;

				// prepare about cards
				self.cards = [
					new App.Components.IntroCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'introduction'} ).toJSON().data)} ),
					new App.Components.ContactCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'contact'} ).toJSON().data)} ),
					new App.Components.SkillCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'skills'} ).toJSON().data)} ),
					new App.Components.HistoryCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'history'} ).toJSON().data)} ),
					new App.Components.LanguageCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'languages'} ).toJSON().data)} ),
					new App.Components.InterestCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'interests'} ).toJSON().data)} ),
					new App.Components.SoftwareCard( {collection: new App.AboutCollection(collection.findWhere( {title: 'software'} ).toJSON().data)} )
				];


				// prepare template
				self.$el = $(self.template( ));
				self.$el.addClass('About--hidden');
				var $cards = self.$el.find('.About-cards');

				// add cards to view
				for (var i = 0; i < self.cards.length; i++) {
					$cards.append(self.cards[i].$el);
				}

				// prepare & add mail overlay
				self.mailOverlay = new App.Views.MailOverlay();
				self.mailOverlay.prepare();
				
				// eventlisteners for mail overlay
				self.$el.on('click', '.ContactCard-item--email, .About-callToAction', self.onMailClick.bind(self));

				$('title').html('Nicolas Jaenen - About');


				// hide preloader
				App.preloader.hide();
				
				App.router.onPrepare(self);
			});

		},

		show: function () {
			App.DomView.append(this.$el);
			App.Timer.set(250, this.showContent.bind(this));
			App.router.onShow(this);
		},

		update: function () {


			App.router.onUpdate(this);
		},

		hide: function () {
			this.$el.addClass('About--hidden');
			App.Timer.set(1500, this.onHidden.bind(this));
		},

		destroy: function () {
			this.$el.remove();
			this.remove();
			this.unbind();

			App.router.onDestroy(this);
		},

		showContent: function () {
			this.$el.removeClass('About--hidden');
		},

		onHidden: function () {
			App.router.onHide(this);
		},

		onMailClick: function (e) {
			e.preventDefault();
			this.mailOverlay.show();
		}
	};

	return Backbone.View.extend(About);

})();