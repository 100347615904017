App.Components.Navigation = (function () {
	var template = TPL['templates/components/navigation.hbs'];

	var Navigation = {

		initialize: function () {

		},

		prepare: function () {
			this.$el = $(template( {pages: _.sortBy(App.Config.pages, 'order')} ));
			this.$el
				.addClass('Navigation--hidden')
				.addClass('Navigation--showDelay')
				.on('click', '.Navigation-item', onItemClick.bind(this));

			App.DomView.prepend(this.$el);
		},

		show: function () {

			var self = this;

			App.Timer.set(250, function () {
				self.$el.removeClass('Navigation--hidden');
			});

			App.Timer.set(1250, function (){
				self.$el.removeClass('Navigation--showDelay');
			});
		},

		update: function () {

		},

		hide: function () {

		},

		destroy: function () {

		},

		setCurrentItemById: function (id) {
			var $item = this.$el.find('.Navigation-item[data-id=' + [id || 0] + ']');

			if ($item.length) {
				this.$el.find('.Navigation-item').removeClass('Navigation-item--active');
				$item.addClass('Navigation-item--active');
			}
		},

		setCurrentItemByPage: function (page) {
			this.setCurrentItemById (page.id);
		},

		setCurrentItemByName: function (name) {
			var criteria = (name === '')? { default: true } : { name: name };
			var p = _.find(App.Config.pages, criteria);		
			this.setCurrentItemById (p.id);
		}
	};

	// private functions
	function onItemClick(e) {
		e.preventDefault();

		var $target = $(e.currentTarget);
		if (!$target.hasClass('Navigation-item--active')) {
			this.setCurrentItemById($target.attr('data-id'));
		}
	}

	return Backbone.View.extend(Navigation);
})();