App.Views.Blog = (function () {

	var Blog = {

		template: TPL['templates/blog.hbs'],

		initialize: function () {
			var fragment = Backbone.history.fragment.split('/');
			App.navigation.setCurrentItemByName(fragment[0]);

			$('title').html('Nicolas Jaenen - Blog');
		},

		prepare: function () {
			this.$el = $(this.template());

			App.router.onPrepare(this);
		},

		show: function () {
			App.DomView.append(this.$el);

			App.router.onShow(this);
		},

		update: function () {


			App.router.onUpdate(this);
		},

		hide: function () {


			App.router.onHide(this);
		},

		destroy: function () {
			this.$el.remove();
			this.remove();
			this.unbind();

			App.router.onDestroy(this);
		}
	};

	return Backbone.View.extend(Blog);

})();