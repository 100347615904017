App.Components.SoftwareCard = (function () {

	var SoftwareCard = {

		template: TPL['templates/components/softwareCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;

			for (var i = 0; i < this.collection.length; i ++) {
				var model = this.collection.at(i);
				var d = this.getSoftwareGraphData(model.toJSON());

				model.set('x', d.x);
				model.set('height', d.height);
				model.set('y', d.y);
				model.set('textX',  d.textX);

			}

			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
		},

		getSoftwareGraphData: function (software) {
			var h = software.score / 100 * 174;
			return {
				x: (software.id-1) * 49 + 80,
				y: 204 - h,
				height: h,
				textX: -h + 8
			};
		}
	};

	return Backbone.View.extend(SoftwareCard);
})();