
Handlebars.registerHelper('space2_', function(v) {
	return v.replace(/ /g, "_");
});

/*
	For

	{{#for [from] [to] [incr]}}
	{{/for}}
*/
Handlebars.registerHelper('for', function(from, to, incr, block) {
	var accum = '';
	for(var i = from; i < to; i += incr) {
		accum += block.fn(i);
	}
	return accum;
});
Handlebars.registerHelper('forto', function(from, to, incr, block) {
	var accum = '';
	for(var i = from; i <= to; i += incr) {
		accum += block.fn(i);
	}
	return accum;
});

/*
	If condition => handlebars if uses only true/false

	{{#ifCond [v1] [v2]}}
	{{/else}}
	{{/if}}
*/
Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

	switch (operator) {
		//case '==':
			//return (v1 == v2) ? options.fn(this) : options.inverse(this);
		case '===':
			return (v1 === v2) ? options.fn(this) : options.inverse(this);
		case '!==':
			return (v1 !== v2) ? options.fn(this) : options.inverse(this);
		case '<':
			return (v1 < v2) ? options.fn(this) : options.inverse(this);
		case '<=':
			return (v1 <= v2) ? options.fn(this) : options.inverse(this);
		case '>':
			return (v1 > v2) ? options.fn(this) : options.inverse(this);
		case '>=':
			return (v1 >= v2) ? options.fn(this) : options.inverse(this);
		case '&&':
			return (v1 && v2) ? options.fn(this) : options.inverse(this);
		case '||':
			return (v1 || v2) ? options.fn(this) : options.inverse(this);
		default:
			return options.inverse(this);
	}
});

/*
	ReadJqEl => returns html inside the jquery element
*/
Handlebars.registerHelper('readJqEl', function (v) {
	return v.get(0);
});

/*
	Times

	{{#times [int]}}
	{{/times}}
*/
Handlebars.registerHelper('times', function(n, block) {
	var accum = '';
	for(var i = 0; i < n; ++i){
		accum += block.fn(i);
	}
	return accum;
});