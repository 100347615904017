App.Components.LanguageCard = (function () {

	var LanguageCard = {

		template: TPL['templates/components/languageCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;

			for (var i = 0; i < this.collection.length; i ++) {
				var model = this.collection.at(i);
				model.set('arc', this.createArcData(model.get('score')));
			}

			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
		},

		createArcData: function (percentage) {
			var endDeg = 	(percentage / 100 * 360) - 0.01;
			var start = 	App.Util.polarToCartesian (45, 45, 38, 0).join(','),
				end = 		App.Util.polarToCartesian(45, 45, 38, endDeg).join(','),
				flag = 		(endDeg > 180)? 1 : 0;

			return 'M ' + start + ' A 38,38 0 ' + flag + ',1 ' + end;
		}
	};

	return Backbone.View.extend(LanguageCard);
})();