App.Components.IntroCard = (function () {

	var IntroCard = {

		template: TPL['templates/components/introCard.hbs'],

		initialize: function (options) {
			this.model = options.collection.at(0);
			this.render();
		},

		render: function () {
			var modelJSON = this.model.toJSON();
			modelJSON.content = App.Util.nl2p(modelJSON.content, 'IntroCard-content');
			this.$el = $(this.template(modelJSON));
		}
	};

	return Backbone.View.extend(IntroCard);
})();