App.Views.MailOverlay = (function () {

	var MailOverlay = {

		template: TPL['templates/components/mailOverlay.hbs'],

		messages: {
			titleSuccess: 	'Thank you!',
			titleFail: 		'Oops...',
			noName: 		'Can you tell who you are?',
			noEmail: 		'I need this to get back to you.',
			errorEmail: 	'Something seems to be wrong here.',
			noMessage: 		'Why would you send an empty message?',
			success: 		'I\'ll get back to you as quick as I can',
			errorSend: 		'My server is talking gibberish again,\nI\'m not sure your message went through.'
		},

		initialize: function () {

		},

		prepare: function () {
			this.$el = $(this.template({}));
		},

		show: function () {
			this.$el
				.addClass('MailOverlay--hidden')
				.removeClass('MailOverlay--sent');

			this.$el.on('click', '.MailOverlay-dimmer, .MailOverlay-btnClose', this.onBtnCloseClick.bind(this));
			this.$el.on('submit', '.MailOverlay-form', this.onSubmit.bind(this));

			App.DomView.append(this.$el);

			App.Timer.set(50, this.onShow.bind(this));
		},

		update: function (state) {
			switch (state) {
				case 'sent':
					this.$el.addClass('MailOverlay--sent');
				break;

				default: 
					this.$el.removeClass('MailOverlay--sent');
				break;
			}
		},

		hide: function () {
			this.$el.addClass('MailOverlay--hidden');
			App.Timer.set(500, this.onHidden.bind(this));
		},

		destroy: function () {

		},

		onShow: function () {
			this.$el.removeClass('MailOverlay--hidden');
		},

		onHidden: function () {
			this.remove();
		},

		onBtnCloseClick: function (e) {
			e.preventDefault();
			this.hide();
			return false;
		},

		onSubmit: function (e) {
			e.preventDefault();
			console.log('SUBMIT!', e);

			var form = e.currentTarget;
			var error = false;
			for (var i = 0; i < form.length; i++) {
				var $formEl = $(form[i]);
				var name = $formEl.attr('name'),
					value = $formEl.val(),
					$item = $formEl.parent();

				switch (name) {
					case 'txtName':
						if (value === '') {
							this.$el.find('.MailOverlay-error--txtName').html(this.messages.noName);
							$item.addClass('MailOverlay-formItem--error');
							error = true;
						}
						else {
							$item.removeClass('MailOverlay-formItem--error');
						}
					break;

					case 'txtEmail':
						if (value === '') {
							this.$el.find('.MailOverlay-error--txtEmail').html(this.messages.noEmail);
							$item.addClass('MailOverlay-formItem--error');
							error = true;
						}
						else if (!value.match(/[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/) ) {
							this.$el.find('.MailOverlay-error--txtEmail').html(this.messages.errorEmail);
							$item.addClass('MailOverlay-formItem--error');
							error = true;
						}
						else {
							$item.removeClass('MailOverlay-formItem--error');
						}
					break;

					case 'txtMessage':
						if (value === '') {
							this.$el.find('.MailOverlay-error--txtMessage').html(this.messages.noMessage);
							$item.addClass('MailOverlay-formItem--error');
							error = true;
						}
						else {
							$item.removeClass('MailOverlay-formItem--error');
						}
					break;
				}
			}

			if (error) {
				console.log('error');
			}
			else {
				var self = this;
				$.post( App.Config.api.url + 'mail', $(form).serialize())
					.done(function () {
						self.$el.addClass('MailOverlay--sent');

						self.$el.find('.MailOverlay-feedback .MailOverlay-title')
								.html(self.messages.titleSuccess)
								.parent()
								.find('.MailOverlay-feedbackText')
								.html(self.messages.success);
					})
					.fail(function () {
						self.$el.addClass('MailOverlay--sent');

						self.$el.find('.MailOverlay-feedback .MailOverlay-title')
								.html(self.messages.titleFail)
								.parent()
								.find('.MailOverlay-feedbackText')
								.html(self.messages.fail);
					});
			}
		}
	};

	return Backbone.View.extend(MailOverlay);

})();