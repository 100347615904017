App.Views.D_IconList = (function () {

	var D_IconList = {

		template: TPL['templates/d_iconList.hbs'],

		initialize: function () {

		},

		prepare: function () {
			// look for icon list
			var symbols = $('#svgSpritesheet symbol');

			var icons = Array();
			for (var i = 0; i < symbols.length; i ++) {
				var s = symbols[i];
				var idParts = s.id.split('-');

				if (idParts[1] === 'icon') {
					icons.push(s.id);
				}
			}

			this.$el = $(this.template( {icons: icons} ));


			App.router.onPrepare(this);
		},

		show: function () {
			App.DomView.append(this.$el);

			App.router.onShow(this);
		},

		update: function () {


			App.router.onUpdate(this);
		},

		hide: function () {


			App.router.onHide(this);
		},

		destroy: function () {
			this.$el.remove();
			this.remove();
			this.unbind();

			App.router.onDestroy(this);
		},

		setProject: function (project) {
			this.$el.append(project);
		}
	};

	return Backbone.View.extend(D_IconList);

})();