App.Views.Show = (function () {

	var Show = {

		template: TPL['templates/show.hbs'],

		initialize: function () {
			//this.setCategory('playground'); // debug
		},

		prepare: function () {

			// get title for project detection
			var title = App.Util.getFragment(1).replace(/_/g, ' '),
				self = this;

			App.Util.provideCollection ('allProjectsCollection', App.ProjectCollection, function (collection) {
				
				self.model = App.Instances.allProjectsCollection.findWhere( {title: title} );

				// get type for navigation
				self.type = self.model.get('type');

				if (self.model) {
					var modelJSON = self.model.toJSON();
					modelJSON.social = App.Util.generateShareLinks(modelJSON);

					self.$el = $(self.template({
						project: modelJSON,
						isRetina: App.Util.detectRetinaScreen(),
						isHighDensity: App.Util.detectHighDensityScreen()
					}));
				}
				else {
					Backbone.router.navigate('/404', {trigger: true});
				}


				self.$el.addClass('Show--hiddenBeforeShow');
				self.$el.find('.Show-footerButton, .Show-socialButton').addClass('Show-footerButton--delay');
				self.$el.on('click', '.Show-footerButton--view', self.onViewClick.bind(self));
				self.$el.on('click', '.Show-socialButton', self.onShareClick.bind(self));

				$('title').html('Nicolas Jaenen - ' + self.model.get('title'));


				// hide preloader
				App.preloader.hide();

				App.router.onPrepare(self);
			});
		},

		show: function () {
			App.DomView.append(this.$el);

			App.Timer.set(0, this.showContent.bind(this));
			App.Timer.set(3000, this.removeFooterButtonDelay.bind(this));

			App.router.onShow(this);
		},

		update: function () {
			App.router.onUpdate(this);
		},

		hide: function () {
			this.$el.addClass('Show--hiddenAfterShow');
			App.Timer.set(1000, this.onHide.bind(this));
		},

		destroy: function () {
			this.$el.remove();
			this.remove();
			this.unbind();

			App.router.onDestroy(this);
		},

		setProject: function (project) {
			this.$el.append(project);
		},

		setCategory: function (category) {
			// needs to be set by model
			this.category = category;
			App.navigation.setCurrentItemByName(category);
		},

		showContent: function () {
			this.$el.removeClass('Show--hiddenBeforeShow');
		},

		onHide: function () {
			App.router.onHide(this);
		},

		removeFooterButtonDelay: function () {
			this.$el.find('.Show-footerButton, .Show-socialButton').removeClass('Show-footerButton--delay');
		},

		onViewClick: function () {
			ga("send", "event", "Nicolas Jaenen - " + this.model.get('title'), "click", "view", "live");
		},

		onShareClick: function(e) {
			ga("send", "event", "Nicolas Jaenen - " + this.model.get('title'), "click", "share", (this).attr('data-id'));
		}
	};

	return Backbone.View.extend(Show);

})();