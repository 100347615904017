App.Views.ProjectList = (function () {

	var ProjectList = {

		template: TPL['templates/projectList.hbs'],

		initialize: function () {
			this.ready = false;
		},

		prepare: function () {
			// get fragment and set navigation item
			var fragment = Backbone.history.fragment.split('/');
			App.navigation.setCurrentItemByName(fragment[0]);

			// grab page object from config
			var criteria = (fragment[0] === '') ? { default: true } : { name: fragment[0] };
			this.page = _.find(App.Config.pages, criteria);

			// check & fill allprojects collection
			var self = this;
			App.Util.provideCollection ('allProjectsCollection', App.ProjectCollection, function (collection) {
				self.collection = App.Instances.allProjectsCollection.filterByType(self.page.model).filterVisibleOnly();

				// render template
				self.$el = $(self.template({
					items: self.collection.toJSON(),
					isRetina: App.Util.detectRetinaScreen(),
					isHighDensity: App.Util.detectHighDensityScreen()
				}));

				self.$items = self.$el.find('.ProjectItem');
				self.$items.addClass('ProjectItem--hidden');


				// hide preloader
				App.preloader.hide();
				
				App.router.onPrepare(self);
			});

			$('title').html('Nicolas Jaenen - ' + this.page.title);
		},

		show: function () {
			App.DomView.append(this.$el);

			App.Timer.set(250, this.showItems.bind(this));

			App.router.onShow(this);
		},

		update: function () {


			App.router.onUpdate(this);
		},

		hide: function () {
			this.$items
				.addClass('ProjectItem--hideAnimation')
				.addClass('ProjectItem--hidden');

            // only animate those visible (other animations are allowed to break)
            var viewport = App.Util.getViewportBounds(),
            	delay = 0;

            for (var i = 0; i < this.$items.length; i ++) {
                var item = $(this.$items[i]);
                
                if (App.Util.isInViewport(item, viewport)) {
                	delay += 100;
                }
            }

            App.Timer.set(1000 + delay, this.onItemsHidden.bind(this));
		},

		destroy: function () {
			this.$el.remove();
			this.remove();
			this.unbind();

			App.router.onDestroy(this);
		},

		onAllProjectsFetched: function () {
			App.router.onPrepare(this);
		},

		onItemClick: function (e) {
			e.preventDefault();
			console.log('item clicked', e);
		},

		showItems: function () {
			this.$items.removeClass('ProjectItem--hidden');
		},

		onItemsHidden: function () {
			App.router.onHide(this);
		}
	};

	return Backbone.View.extend(ProjectList);

})();