App.Components.SkillCard = (function () {

	var SkillCard = {

		template: TPL['templates/components/skillCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;
			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
		}
	};

	return Backbone.View.extend(SkillCard);
})();