App.ProjectCollection = (function () {
	var ProjectCollection = {
		url: 	App.Config.api.url + 'projects',
		model: 	App.ProjectModel,

		filterByType: function (type) {
			var filtered = this.filter(function (record) {
				return record.get('type') === type;
			});

			return new App.ProjectCollection(filtered);
		},

		filterVisibleOnly: function () {
			var filtered = this.filter(function (record) {
				return record.get('visible') === '1';
			});

			return new App.ProjectCollection(filtered);
		}
	};

	return Backbone.Collection.extend(ProjectCollection);
})();