App.Components.InterestCard = (function () {

	var InterestCard = {

		template: TPL['templates/components/interestCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;
			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
		}
	};

	return Backbone.View.extend(InterestCard);
})();