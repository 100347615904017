App.Timer = (function () {
	var idCounter = 0;

	var timer = {
		timers: Array(),
		running: false,
		firstRun: true,
		lastTick: 0,

		debug: false,

		set: function (delay, callback) {
			if (this.debug) {
				console.log('[Timer] set: ', delay, callback);
			}
			this.timers.push({
				id: idCounter++,
				duration: delay,
				delay: delay,
				callback: callback
			});

			if (this.firstRun) {
				if (this.debug) {
					console.log('[Timer] First run, checking for api\'s');
				}				App.ClientProvider.requestAnimationFrame();
				this.firstRun = false;
			}

			if (!this.running) {
				if (this.debug) {
					console.log('[Timer] Ticker not running, starting');
				}
				this.lastTick = (new Date()).getTime();
				requestAnimationFrame(tick.bind(this));
				this.running = true;
			}
		}
	};

	function tick() {
		var currentTick = (new Date()).getTime();
		var tickDuration = currentTick - this.lastTick;

		for (var i = 0; i < this.timers.length; i++) {
			var t = this.timers[i];

			t.duration -= tickDuration;

			if (t.duration <= 0) {
				if (this.debug) {
					console.log('[Timer] fire: ', t.id, 'was set for', t.delay);
				}
				t.callback();
				this.timers.splice(i, 1);
			}

		}

		this.lastTick = currentTick;

		if (this.timers.length) {
			requestAnimationFrame(tick.bind(this));
		}
		else {
			if (this.debug) {
				console.log('[Timer] No timers left, ticker halted');
			}
			this.running = false;
		}
	}

	return timer;

})();