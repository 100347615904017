App.Config = {
	
	tracking: {
		active: true
	},

	api: {
		rootUrl: 	'/api/',
		dummy: 		false,
		dummyUrl: 	'/api/dummy/'
	},

	pages: [
		{id: 0, order: 0, name: 'projects', title: 'Projects', view: 'ProjectList', model: 'projects', visible: true, default: true},
		{id: 1, order: 1, name: 'playground', title: 'Playground', view: 'ProjectList', model: 'playground', visible: true, default: false},
		{id: 2, order: 2, name: 'blog', title: 'Blog', view: 'Blog', model: 'blogList', visible: false, default: false},
		{id: 3, order: 3, name: 'about', title: 'About', view: 'About', model: '', visible: true, default: false}
	]
};


// some App.Config processing
App.Config.api.url = (App.Config.api.dummy) ? App.Config.api.dummyUrl : App.Config.api.rootUrl;