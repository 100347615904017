App.Components.ContactCard = (function () {

	var ContactCard = {

		template: TPL['templates/components/contactCard.hbs'],

		initialize: function (options) {
			this.collection = options.collection;
			this.render();
		},

		render: function () {
			this.$el = $(this.template( {items: this.collection.toJSON()} ));
			this.$el.on('click', '.ContactCard-item', this.onContactClick.bind(this));
		},

		onContactClick: function () {
			ga("send", "event", "Nicolas Jaenen - About", "click", "contact", $(this).attr('data-type'));
		}
	};

	return Backbone.View.extend(ContactCard);
})();